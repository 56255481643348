import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ResumePage = () => (
  <Layout>
    <SEO title="Resume" />
    <h1>Resume</h1>
    <p>
      My <a href="https://www.linkedin.com/in/igierard">LinkedIn Profile</a> has my most up-to-date information but a <a href="/Isaac-Gierard-Resume.pdf" target="_blank">PDF resume</a> is also available.
    </p>
  </Layout>
)

export default ResumePage
